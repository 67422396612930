import React, { useEffect, useMemo, useState } from 'react';
import {
    displayPeriodTypes,
    convertSelectionsToFilterPayloadFor,
    getMetadataRequestParams,
    buildStringToComparisonTypeString,
} from 'components/financials/comparative-income-statement/banner/ComparisonSelectionsBannerUtils';
import {
    COMPARATIVE_INCOME_STATEMENT_TABLE_HEIGHT,
    COMPARATIVE_INCOME_STATEMENT_TABLE_MIN_WIDTH,
    SelectedPeriodDisplayName,
    headerStyle,
    getComparativeIncomeStatementTableColumns,
    tableContainerStyle,
} from 'components/financials/comparative-income-statement/table/ComparativeIncomeStatementTableUtils';
import {
    filterAccountGraph,
    getAllParentAccountMappingCodes,
    getEveryNodeValueFor,
} from 'waypoint-utils';
import { FinancialTreeTable } from 'waypoint-react';
import Skeleton from 'antd/lib/skeleton';
import { ComparisonSelections } from 'components/financials/comparative-income-statement/ComparisonIncomeStatementTypes';
import {
    HAS_COMMENTS,
    HIDE_NULLS,
    SHOW_ALL_NOTES,
    SHOW_OVER_THRESHOLDS,
} from 'waypoint-utils/account-graphs/constants';
import moment from 'moment';
import {
    COLUMN_A_NAME,
    COLUMN_B_NAME,
    COMPARISON_TYPE,
    COMPARISON_PERIOD,
    VARIANCE_DISPLAY,
    VARIANCE_DISPLAY_TERTIARY,
    COLUMN_C_NAME,
    COMPARISON_PERIOD_TERTIARY,
    ACTUAL_TO_BUDGET_VALUE,
    NONE_VALUE,
    FINANCIAL_YEAR_ENDING,
    SELECTED_PERIOD_VALUE,
} from 'components/financials/comparative-income-statement/constants';
import { ClientMode, CommentMention } from 'waypoint-types';
import { connect, RootStateOrAny } from 'react-redux';
import { selectCurrentUser } from 'state/user/selectors';
import FinancialTableNoteModal from 'components/notes/FinancialTableNoteModal';
import { useIncomeStatement } from 'components/financials/comparative-income-statement/IncomeStatementProvider';
import AccountHistoryModal from 'components/financials/comparative-income-statement/table/AccountHistoryModal';
import { useCommentsPane } from 'contexts/comments/CommentsContext';
import {
    useGetMentionableUsers,
    useGetWorkflowRoles,
    useIncomeStatementSettings,
} from 'waypoint-hooks';
import { roles as userRoles } from 'config/constants';
import { FinancialExportOptions } from 'components/reports/constants';
import { capitalize } from 'lodash';

interface ExtraReportWidgetSettings {
    is_hide_null: boolean;
    is_hide_notes: boolean;
    show_per_sf: boolean;
    show_per_unit: boolean;
    show_current_period: boolean;
    export_setting: string;
    account_mapping_selections: string[];
}

interface ComparativeIncomeStatementTableParams {
    entityCodes: string[];
    selections: ComparisonSelections;
    userId: number;
    isAdmin: boolean;
    selectedAccountMappingId: string;
    setSelectedAccountMappingId: (value: string | null) => void;
    commentThreadAccountMentions: CommentMention[];
    setAccountFilterSelection: (value: string | null) => void;
    modes: ClientMode[];
    totalRSF: number;
    totalUnits: number;
    metadataId?: string;
    heightOffset: number;
    reportWidgetSettings?: ExtraReportWidgetSettings;
    isPDFExport?: boolean;
    setIsReadyToExport?: (value: boolean) => void;
}

export const ComparativeIncomeStatementTable = ({
    entityCodes,
    selections,
    userId,
    isAdmin,
    selectedAccountMappingId,
    setSelectedAccountMappingId,
    commentThreadAccountMentions,
    setAccountFilterSelection,
    modes,
    totalRSF,
    totalUnits,
    metadataId,
    heightOffset,
    reportWidgetSettings,
    isPDFExport,
    setIsReadyToExport,
}: ComparativeIncomeStatementTableParams): JSX.Element => {
    const {
        hideNull,
        setHideNull,
        showCurrentPeriod,
        setShowCurrentPeriod,
        showPerSF,
        setShowPerSF,
        showPerUnit,
        setShowPerUnit,
        comparisonSelections,
        showThresholdButton,
        filterOverThreshold,
        showAllNotes,
        hasComments,
        hideNotes,
        setHideNotes,
        isLoadingReportNotes,
        reportNotes,
        mutateReportNotes,
        incomeStatementNodes,
        mentionableAccounts,
        isError,
        isLoading,
        selectedDataLevel,
    } = useIncomeStatement();

    const { comparisonTypeOptions } = useIncomeStatementSettings(entityCodes);

    const userMentionOptions = useGetMentionableUsers(entityCodes);
    const { data: workflowRoles } = useGetWorkflowRoles(
        entityCodes,
        (userMentionOptions ?? []).map((user) => {
            return {
                id: Number(user.id),
                name: user.text,
                profile_image_url: user.profile_image_url ?? '',
            };
        }),
    );

    const { openComments } = useCommentsPane();

    const [isNotesModalVisible, setIsNotesModalVisible] =
        useState<boolean>(false);
    const [isAccountHistoryModalVisible, setIsAccountHistoryModalVisible] =
        useState<boolean>(false);
    const [noteId, setNoteId] = useState<string | null>(null);
    const [noteText, setNoteText] = useState<string>('');
    const [expandedRows, setExpandedRows] = useState<string[]>([]);

    useEffect(() => {
        if (reportWidgetSettings) {
            setHideNotes(reportWidgetSettings.is_hide_notes);
            setHideNull(reportWidgetSettings.is_hide_null);
            setShowPerSF(reportWidgetSettings.show_per_sf);
            setShowPerUnit(reportWidgetSettings.show_per_unit);
            setShowCurrentPeriod(reportWidgetSettings.show_current_period);
        }
    }, [reportWidgetSettings]);

    useMemo(() => {
        if (
            incomeStatementNodes &&
            reportWidgetSettings?.export_setting ===
                FinancialExportOptions.FULL_DETAIL
        ) {
            const fullDetailExpandedRows =
                getAllParentAccountMappingCodes(incomeStatementNodes);
            setExpandedRows(fullDetailExpandedRows);
        }
    }, [reportWidgetSettings, incomeStatementNodes]);

    const getSelectionFor = (field: string) => {
        return selections[field as keyof ComparisonSelections];
    };

    /**
     * This returns fields and values that are used to read account data in a account graph
     * @param {string} column - name of column
     * @return {object}
     */

    const getColumnIndex = (column: string) => {
        if (column === COLUMN_A_NAME) {
            return 0;
        }
        if (column === COLUMN_B_NAME) {
            return 1;
        }
        return 2;
    };

    const getPredicatesFor = (column: string) => {
        const convertedSelections = convertSelectionsToFilterPayloadFor(
            column,
            selections,
            getColumnIndex(column),
        );
        return {
            period_start: convertedSelections.start_date,
            period_end: convertedSelections.end_date,
            mode: convertedSelections.mode,
        };
    };

    const includeNotes = entityCodes.length <= 1 && !hideNotes;
    const includeStatus = entityCodes.length <= 1 && showThresholdButton;

    const hideNotesOption = reportWidgetSettings !== undefined && hideNotes;
    const hideCommentsOption = reportWidgetSettings !== undefined;

    const formatDisplayDate = (dateString: string) => {
        return moment(dateString).format(`MMM 'YY`);
    };

    const getSelectionMonthDate = (column: string) => {
        const displayDate = getSelectionFor(`period_${column}`);
        return moment(displayDate[1].toString()).format(`MMM 'YY`);
    };

    const getTopLineText = (column: string) => {
        const comparisonTypeParts = getSelectionFor(
            COMPARISON_TYPE,
        ) as string[];
        const topLineIndex = getColumnIndex(column);
        const selectedMode = comparisonTypeParts[topLineIndex];
        return (
            modes.find((mode) => mode.code === selectedMode)?.display_name ??
            `${capitalize(selectedMode)}`
        );
    };

    const getCurrentPeriodHeaders = (column: string) => {
        const topLineText = getTopLineText(column);
        let middleLineText = displayPeriodTypes['mtd'];
        if (column !== COLUMN_A_NAME) {
            const comparisonPeriod = getSelectionFor(
                column === COLUMN_B_NAME
                    ? COMPARISON_PERIOD
                    : COMPARISON_PERIOD_TERTIARY,
            );

            if (comparisonPeriod !== SELECTED_PERIOD_VALUE) {
                middleLineText =
                    SelectedPeriodDisplayName[
                        comparisonPeriod as keyof typeof SelectedPeriodDisplayName
                    ];
            }
        }

        const bottomLineText = getSelectionMonthDate(column);
        return { topLineText, middleLineText, bottomLineText };
    };

    const formatColumnHeaders = (column: string, currentPeriod?: boolean) => {
        if (currentPeriod) {
            const { topLineText, middleLineText, bottomLineText } =
                getCurrentPeriodHeaders(column);
            return (
                <div className={headerStyle}>
                    <div style={{ marginBottom: '5px' }}>{topLineText}</div>
                    <div style={{ marginBottom: '5px' }}>{middleLineText}</div>
                    <div>{bottomLineText}</div>
                </div>
            );
        }

        const displayDate = getSelectionFor(`period_${column}`);

        const currentPeriodicity = selections['periodicity'];

        const topLineText = getTopLineText(column);

        const formattedStartDate = formatDisplayDate(displayDate[0].toString());
        const formattedEndDate = formatDisplayDate(displayDate[1].toString());

        const comparisonPeriod =
            column === COLUMN_C_NAME
                ? getSelectionFor(COMPARISON_PERIOD_TERTIARY)
                : getSelectionFor(COMPARISON_PERIOD);

        const middleLineText =
            column === COLUMN_A_NAME || comparisonPeriod === 'selected_period'
                ? displayPeriodTypes[
                      currentPeriodicity as keyof typeof displayPeriodTypes
                  ]
                : SelectedPeriodDisplayName[
                      comparisonPeriod as keyof typeof SelectedPeriodDisplayName
                  ];

        const bottomLineText =
            formattedStartDate === formattedEndDate
                ? formattedEndDate
                : `${formattedStartDate} - ${formattedEndDate}`;

        return (
            <div className={headerStyle}>
                <div style={{ marginBottom: '5px' }}>{topLineText}</div>
                <div style={{ marginBottom: '5px' }}>{middleLineText}</div>
                <div>{bottomLineText}</div>
            </div>
        );
    };

    const hideAccountHistoryButton =
        selections.periodicity === 'custom' ||
        buildStringToComparisonTypeString(
            selections.comparison_type.slice(0, 2),
        ) !== ACTUAL_TO_BUDGET_VALUE ||
        selections.comparison_type[2] !== NONE_VALUE ||
        selections.comparison_period !== 'selected_period';

    const includeActionColumn =
        entityCodes.length <= 1 &&
        !(hideNotesOption && hideCommentsOption && hideAccountHistoryButton);

    const isYearTotal = selections.comparison_period === 'year_total';
    const mtdColumns =
        selections.comparison_type[1] === NONE_VALUE
            ? [
                  {
                      header: formatColumnHeaders(COLUMN_A_NAME, true),
                      predicates: getPredicatesFor(COLUMN_A_NAME),
                      mtd: true,
                  },
              ]
            : [
                  {
                      header: formatColumnHeaders(COLUMN_A_NAME, true),
                      predicates: getPredicatesFor(COLUMN_A_NAME),
                      mtd: true,
                  },
                  {
                      header: formatColumnHeaders(COLUMN_B_NAME, !isYearTotal),
                      predicates: getPredicatesFor(COLUMN_B_NAME),
                      mtd: !isYearTotal,
                  },
              ];

    const selectionColumns =
        selections.comparison_type[1] === NONE_VALUE
            ? [
                  {
                      header: formatColumnHeaders(COLUMN_A_NAME),
                      predicates: getPredicatesFor(COLUMN_A_NAME),
                  },
              ]
            : [
                  {
                      header: formatColumnHeaders(COLUMN_A_NAME),
                      predicates: getPredicatesFor(COLUMN_A_NAME),
                  },
                  {
                      header: formatColumnHeaders(COLUMN_B_NAME),
                      predicates: getPredicatesFor(COLUMN_B_NAME),
                  },
              ];

    if (
        selections.comparison_type.length === 3 &&
        selections.comparison_type[1] !== NONE_VALUE &&
        selections.comparison_type[2] !== NONE_VALUE
    ) {
        selectionColumns.push({
            header: formatColumnHeaders(COLUMN_C_NAME),
            predicates: getPredicatesFor(COLUMN_C_NAME),
        });
        const isTertiaryYearTotal =
            selections.comparison_period_tertiary === 'year_total';
        mtdColumns.push({
            header: formatColumnHeaders(COLUMN_C_NAME, !isTertiaryYearTotal),
            predicates: getPredicatesFor(COLUMN_C_NAME),
            mtd: !isTertiaryYearTotal,
        });
    }

    const mainColumns = getComparativeIncomeStatementTableColumns(
        selectionColumns,
        includeStatus,
        includeNotes,
        includeActionColumn,
        showPerSF,
        showPerUnit,
        false,
        totalRSF,
        totalUnits,
        getSelectionFor(VARIANCE_DISPLAY) as string,
        userId,
        mutateReportNotes,
        isNotesModalVisible,
        setIsNotesModalVisible,
        setNoteId,
        setNoteText,
        setSelectedAccountMappingId,
        openComments,
        setAccountFilterSelection,
        entityCodes.length === 1,
        reportNotes,
        expandedRows,
        commentThreadAccountMentions,
        isAccountHistoryModalVisible,
        setIsAccountHistoryModalVisible,
        hideAccountHistoryButton,
        hideNotesOption,
        hideCommentsOption,
        isPDFExport,
        workflowRoles,
        isAdmin,
        getSelectionFor(VARIANCE_DISPLAY_TERTIARY) as string,
        !!showCurrentPeriod,
        comparisonSelections,
        comparisonTypeOptions,
    );

    const currentPeriodColumns = getComparativeIncomeStatementTableColumns(
        mtdColumns,
        true,
        false,
        false,
        showPerSF,
        showPerUnit,
        true,
        totalRSF,
        totalUnits,
        getSelectionFor(VARIANCE_DISPLAY) as string,
        userId,
        mutateReportNotes,
        isNotesModalVisible,
        setIsNotesModalVisible,
        setNoteId,
        setNoteText,
        setSelectedAccountMappingId,
        openComments,
        setAccountFilterSelection,
        entityCodes.length === 1,
        reportNotes,
        expandedRows,
        commentThreadAccountMentions,
        isAccountHistoryModalVisible,
        setIsAccountHistoryModalVisible,
        hideAccountHistoryButton,
        hideNotesOption,
        hideCommentsOption,
        isPDFExport,
        workflowRoles,
        isAdmin,
        getSelectionFor(VARIANCE_DISPLAY_TERTIARY) as string,
        false,
        comparisonSelections,
        comparisonTypeOptions,
        'current_period_calculations',
        'current_period_tertiary_calculations',
    );

    useEffect(() => {
        if (!incomeStatementNodes) {
            return;
        }
        const filteredData =
            filterOverThreshold || showAllNotes || hasComments
                ? filterAccountGraph(
                      incomeStatementNodes,
                      filterArray,
                      reportNotes,
                      commentThreadAccountMentions,
                  )
                : [];
        setExpandedRows(getEveryNodeValueFor(filteredData, 'key'));
    }, [hideNull, filterOverThreshold, showAllNotes, hasComments]);

    const isSmallScreen = window.innerWidth < 1024 || window.innerHeight < 900;

    if (isError) {
        return (
            <div
                style={{
                    height: COMPARATIVE_INCOME_STATEMENT_TABLE_HEIGHT,
                    padding: 20,
                }}
            >
                <span>
                    There was an error retrieving income statement data.
                </span>
            </div>
        );
    }

    if (!incomeStatementNodes || isLoading) {
        return (
            <div
                style={{
                    height: COMPARATIVE_INCOME_STATEMENT_TABLE_HEIGHT,
                    padding: 20,
                }}
            >
                <Skeleton paragraph={{ rows: isSmallScreen ? 4 : 12 }} />
            </div>
        );
    }

    const filterArray: string[] = [];
    if (hideNull) {
        filterArray.push(HIDE_NULLS);
    }
    let highlightRows: string[] | null = [];
    if (filterOverThreshold) {
        filterArray.push(SHOW_OVER_THRESHOLDS);
        highlightRows = getEveryNodeValueFor(
            filterAccountGraph(
                incomeStatementNodes,
                filterArray,
                reportNotes,
                commentThreadAccountMentions,
            ),
            'key',
        );
    }
    if (showAllNotes) {
        filterArray.push(SHOW_ALL_NOTES);
        highlightRows = getEveryNodeValueFor(
            filterAccountGraph(
                incomeStatementNodes,
                filterArray,
                reportNotes,
                commentThreadAccountMentions,
            ),
            'key',
        );
    }

    if (hasComments) {
        filterArray.push(HAS_COMMENTS);
        highlightRows = getEveryNodeValueFor(
            filterAccountGraph(
                incomeStatementNodes,
                filterArray,
                reportNotes,
                commentThreadAccountMentions,
            ),
            'key',
        );
    }

    const screenSizeOffset = isSmallScreen ? 50 : 0;
    const tableHeight = window.innerHeight - (heightOffset + screenSizeOffset);

    const periods = () => [
        {
            ...convertSelectionsToFilterPayloadFor(
                COLUMN_A_NAME,
                selections,
                0,
            ),
        },
        {
            ...convertSelectionsToFilterPayloadFor(
                COLUMN_B_NAME,
                selections,
                1,
            ),
        },
    ];

    const columns = showCurrentPeriod
        ? [...currentPeriodColumns, ...mainColumns]
        : mainColumns;

    setIsReadyToExport &&
        setIsReadyToExport(!isLoading && !isLoadingReportNotes);

    return (
        <div className={tableContainerStyle(isPDFExport)}>
            <FinancialTreeTable
                size="small"
                bordered={false}
                height={tableHeight}
                data={filterAccountGraph(
                    incomeStatementNodes,
                    filterArray,
                    reportNotes,
                    commentThreadAccountMentions,
                )}
                highlightRows={highlightRows}
                expandedRows={
                    reportWidgetSettings?.account_mapping_selections ??
                    expandedRows
                }
                setExpandedRows={setExpandedRows}
                searchableKeys={['name', 'account_mapping_code']}
                columns={columns}
                isEnablePagination={false}
                data-testid="income-statement-table"
                width={
                    includeNotes && COMPARATIVE_INCOME_STATEMENT_TABLE_MIN_WIDTH
                }
                expandAllRows={
                    reportWidgetSettings?.export_setting ===
                    FinancialExportOptions.FULL_DETAIL
                }
                parentExpand={
                    reportWidgetSettings?.export_setting ===
                    FinancialExportOptions.SUMMARY
                }
                isReportWidget={!!reportWidgetSettings}
                isPDFExport={isPDFExport}
                financialYearEnding={
                    comparisonSelections !== null
                        ? comparisonSelections[FINANCIAL_YEAR_ENDING]
                        : null
                }
                displayFooter={true}
            />
            <FinancialTableNoteModal
                noteId={noteId}
                setNoteId={setNoteId}
                text={noteText}
                setText={setNoteText}
                title={!noteId ? 'Add a Note' : 'Edit Note'}
                isVisible={isNotesModalVisible}
                setIsVisible={setIsNotesModalVisible}
                accountMappingId={selectedAccountMappingId ?? ''}
                setAccountMappingId={setSelectedAccountMappingId}
                metadataParams={getMetadataRequestParams(
                    entityCodes,
                    selections,
                )}
                mutateReportNotes={mutateReportNotes}
            />
            {selectedAccountMappingId &&
                isAccountHistoryModalVisible &&
                entityCodes.length === 1 && (
                    <AccountHistoryModal
                        isVisible={isAccountHistoryModalVisible}
                        setIsVisible={setIsAccountHistoryModalVisible}
                        accountMappingId={selectedAccountMappingId ?? ''}
                        selections={selections}
                        accounts={mentionableAccounts}
                        entityCodes={entityCodes}
                        selectedDataLevel={selectedDataLevel}
                        metadataId={metadataId}
                        periods={periods()}
                    />
                )}
        </div>
    );
};

export const mapState = (state: RootStateOrAny) => {
    const { id: userId, roles } = selectCurrentUser(state);
    const isAdmin = roles.includes(userRoles.CLIENT_ADMIN);

    return {
        userId,
        isAdmin,
    };
};

export default connect(mapState)(ComparativeIncomeStatementTable);
