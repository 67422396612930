import React, { ReactElement, useState, useEffect, useContext } from 'react';
import { get, sortBy } from 'lodash';
import { Alert } from 'antd';

import { getAsOfDates } from 'waypoint-requests';
import { TextLoadingBar } from 'waypoint-react';
import {
    useGetClientData,
    useGetSelectedFilteredEntityCodes,
} from 'waypoint-hooks';
import { FEATURE_AGED_RECEIVABLES } from 'config/constants';
import {
    AppFeaturePermissions,
    EntityAttributesContext,
    EntityAttributesContextType,
    usePermissions,
} from 'contexts';
import {
    AsOfDatesType,
    LastLoadedDateType,
    AccountingAsOfDateType,
    AccountAsOfDateLabel,
    LastLoadedDateLabel,
} from 'components/app/as-of-date';
import { Dictionary } from 'ts-essentials';

const WIDTH = '430px';

const loadingComponent = (
    <TextLoadingBar
        className="" // satisfy typescript
        data-testid-loading
        style={{ width: WIDTH, height: '15px' }}
    />
);

/**
 * Requests client data dump dates on mount. Displays loading text bar until hydrated.
 * @return two Ant Design Alert components, one for accounting data upload dates and lease upload dates
 */

type EntityContextType = EntityAttributesContextType | null;

interface AsOfDateInfoProps {
    singleEntityCode?: string;
}

export function AsOfDateInfo({
    singleEntityCode,
}: AsOfDateInfoProps): ReactElement {
    const { featurePermissions } = usePermissions();

    const configJson: Dictionary<string | number | boolean | null> =
        useGetClientData('config_json');
    const hasAgedReceivablesEnabled = configJson[FEATURE_AGED_RECEIVABLES];

    const [asOfDates, setAsOfDates] = useState<AsOfDatesType | null>(null);

    const entityContext = useContext<EntityContextType>(
        EntityAttributesContext,
    );

    const [isAsOfDatesLoading, setIsAsOfDatesLoading] = useState<boolean>(true);

    const [isError, setIsError] = useState<boolean>(false);

    const entityCodes: string[] = useGetSelectedFilteredEntityCodes();

    useEffect(() => {
        async function fetchAsOfDates() {
            setAsOfDates(null);

            setIsAsOfDatesLoading(true);

            setIsError(false);

            try {
                const data: AsOfDatesType = await getAsOfDates(
                    singleEntityCode ? [singleEntityCode] : entityCodes,
                );

                setAsOfDates(data);

                setIsAsOfDatesLoading(false);

                setIsError(false);
            } catch (error) {
                setAsOfDates(null);

                setIsAsOfDatesLoading(false);

                setIsError(true);
            }
        }
        if (!entityContext?.isAttributesLoading) {
            fetchAsOfDates();
        }
    }, [entityCodes, entityContext?.isAttributesLoading, singleEntityCode]);

    if (isError || entityContext?.isAttributesNetworkError) {
        return (
            <div>
                <Alert
                    data-testid-error
                    message="Error"
                    type="error"
                    description={
                        <div style={{ width: WIDTH }}>
                            There was an error with the request
                        </div>
                    }
                />
            </div>
        );
    }

    const isLoadingAsOfDates =
        isAsOfDatesLoading || entityContext?.isAttributesLoading;

    const accountingAsOfDates: AccountingAsOfDateType[] | null =
        get(asOfDates, 'accounting') || null;

    const leasingAsOfDate: LastLoadedDateType | null =
        get(asOfDates, 'leasing') || null;

    const agedReceivablesAsOfDate: LastLoadedDateType | null =
        get(asOfDates, 'aged_receivables') || null;

    return (
        <div>
            <Alert
                data-testid-financial-alert
                message="Financial Data"
                type="info"
                style={{ marginBottom: '12px' }}
                description={
                    isLoadingAsOfDates ? (
                        loadingComponent
                    ) : (
                        <div style={{ width: WIDTH }}>
                            {sortBy(accountingAsOfDates, 'label').map(
                                (asOfDate, index) => {
                                    return (
                                        <AccountAsOfDateLabel
                                            key={JSON.stringify(index)}
                                            date={asOfDate}
                                        />
                                    );
                                },
                            )}
                        </div>
                    )
                }
            />
            <Alert
                data-testid-leasing-alert
                message="Leasing Data"
                type="info"
                style={{ marginBottom: '12px' }}
                description={
                    isLoadingAsOfDates ? (
                        loadingComponent
                    ) : (
                        <LastLoadedDateLabel date={leasingAsOfDate} />
                    )
                }
            />
            {featurePermissions?.includes(
                AppFeaturePermissions.AgedReceivables,
            ) &&
                hasAgedReceivablesEnabled && (
                    <Alert
                        data-testid-leasing-alert
                        message="Receivables Data"
                        type="info"
                        description={
                            isLoadingAsOfDates ? (
                                loadingComponent
                            ) : (
                                <LastLoadedDateLabel
                                    date={agedReceivablesAsOfDate}
                                />
                            )
                        }
                    />
                )}
        </div>
    );
}
