import {
    AttributeDefinition,
    CustomizeCellInfoType,
    SavedConfiguration,
} from 'waypoint-types';
import { UpdateEntityAttributeValuesParams } from 'waypoint-requests/attributes/createOrUpdateEntityAttributeValues';
import { Dictionary } from 'ts-essentials';
import { css } from 'emotion';
import { AttributeFromAPI } from 'contexts';
import { format } from 'date-fns';
import { stringSort } from 'utils/tables/sorters';
import * as LocalizationTypes from 'devextreme/localization';
import { Column } from 'devextreme-react/data-grid';
import {
    AttributesEditableCellTemplateProps,
    AttributesEditableSelect,
} from './AttributesEditableSelect';
import { formatNumber } from 'components/leases/utils';

export const CARD_HEIGHT_OFFSET = 150;
export const DATA_GRID_HEIGHT_OFFSET = 225;
export const DASHBOARD_MARGIN = 20;

export const cardTitle = css`
    padding: 35px 0 35px 0;
    margin-bottom: 0;
    margin-top: 0;
    display: flex;
`;

export const dataGridStyles = css`
    .dx-datagrid-headers,
    .dx-datagrid-rowsview {
        width: 100% !important;
    }
    .dx-datagrid,
    .dx-datagrid-table {
        table-layout: fixed !important;
    }
`;

export const reservedDataGridAttributesColumns = [
    'entity_display_code',
    'entity_code',
    'name',
    'key',
];

export const formStyle = css`
    overflow-y: scroll;
    .ant-form-item-label {
        font-size: 14px;
        font-family: Lato, serif;
        padding: 0 0 0;
        label {
            font-size: 13px;
        }
    }
    .ant-form-item {
        margin-bottom: 10px;
    }
`;

export const listStyle = css`
    li em {
        display: none;
    }

    li {
        padding: 0 !important;
    }
`;

export type AttributeGridRowValueTypes =
    | string
    | number
    | boolean
    | Date
    | string[];

export type AttributeData = {
    [key in string]: AttributeGridRowValueTypes;
};

export const MAX_NUMBER_DECIMALS = 4;
export const DEFAULT_NUMBER_PRECISION = 0;

export const columnOrdersByKey: Dictionary<number> = {
    name: 0,
    entity_display_code: 1,
};

interface AttributeTypeOption {
    value: string;
    label: string;
}

export enum AttributeTypeCodes {
    STRING = 'string',
    NUMBER = 'number',
    DATETIME = 'datetime',
    BOOLEAN = 'boolean',
}

export const AttributeTypes: Record<AttributeTypeCodes, AttributeTypeOption> = {
    [AttributeTypeCodes.STRING]: {
        value: AttributeTypeCodes.STRING,
        label: 'Text',
    },
    [AttributeTypeCodes.NUMBER]: {
        value: AttributeTypeCodes.NUMBER,
        label: 'Number',
    },
    [AttributeTypeCodes.DATETIME]: {
        value: AttributeTypeCodes.DATETIME,
        label: 'Date',
    },
    [AttributeTypeCodes.BOOLEAN]: {
        value: AttributeTypeCodes.BOOLEAN,
        label: 'Y/N',
    },
};

type AttributeFormatterFunc = (
    value: AttributeGridRowValueTypes,
    precision?: number,
) => string;

function baseNumberFormat(
    value: AttributeGridRowValueTypes,
    precision?: number,
    multiplier = 1,
) {
    return formatNumber(
        (Number(value.toString()) * multiplier).toFixed(precision ?? 0),
    );
}

type AttributeFormatType = Record<
    string,
    { label: string; render: AttributeFormatterFunc }
>;

const validDateFormats = ['MM/dd/yyyy', 'MM/yyyy', 'yyyy-MM-dd'];

export const AttributeFormats: Record<AttributeTypeCodes, AttributeFormatType> =
    {
        [AttributeTypeCodes.NUMBER]: {
            decimal: {
                label: 'Decimal',
                render: baseNumberFormat,
            },
            currency: {
                label: 'Currency',
                render: (value, precision) => {
                    return `$${baseNumberFormat(value.toString(), precision)}`;
                },
            },
            percent: {
                label: 'Percent',
                render: (value, precision) => {
                    return `${baseNumberFormat(value, precision, 100)}%`;
                },
            },
        },
        [AttributeTypeCodes.STRING]: {},
        [AttributeTypeCodes.BOOLEAN]: {},
        [AttributeTypeCodes.DATETIME]:
            validDateFormats.reduce<AttributeFormatType>(
                (result, validFormat) => {
                    result[validFormat] = {
                        label: validFormat.toUpperCase(),
                        render: (value) =>
                            formatDate(value as Date, validFormat),
                    };

                    return result;
                },
                {},
            ),
    };

function formatDate(value: Date, dateFormat: string): string {
    const date = new Date(value as Date);
    const isValidDate = !isNaN(date.getTime());

    return isValidDate ? format(date, dateFormat) : value.toString();
}

export const isFixedColumn = (column: AttributeFromAPI): boolean =>
    column.key === 'name' || column.key === 'entity_display_code';

export const isEditableColumn = (column: AttributeFromAPI): boolean =>
    !isFixedColumn(column) &&
    column.dataIndex !== 'client' &&
    column.dataIndex !== 'jvpartner';

type AttributeType = 'string' | 'date' | 'number' | undefined;

export const mapAttributeType = (
    attributeType?: string | null,
    isEditMode?: boolean,
): AttributeType => {
    // If the attribute is boolean and we're not in edit mode, we should treat it as a string
    if (attributeType === AttributeTypes.boolean.value && !isEditMode) {
        return 'string';
    }
    // we're only supporting date type but for compatibility reasons type name is datetime
    const map: { [key: string]: AttributeType } = {
        [AttributeTypes.datetime.value]: 'date',
        [AttributeTypes.string.value]: 'string',
        [AttributeTypes.number.value]: 'number',
    };

    // Return the mapped type or null if not found
    return map[attributeType || ''] || undefined;
};

export const mapAttributeFormat = (
    attributeType: AttributeTypeCodes | undefined,
    attributeFormat: string | undefined | null,
    attributePrecision?: number | null,
): LocalizationTypes.Format | undefined => {
    if (!attributeFormat || !attributeType) {
        return undefined;
    }

    return {
        formatter: getAttributeFormatter(
            attributeType,
            attributeFormat,
            attributePrecision,
        ),
    };
};

export const getColumnValidationRules = (formatOrType?: string | null) => {
    switch (formatOrType) {
        case 'string':
            return [
                {
                    type: 'stringLength' as const, // Ensure the type is 'stringLength', not a generic string,
                    max: 5000,
                    message: 'The value must be 5000 characters or less.',
                },
            ];
        default:
            return undefined;
    }
};

/*
 * Used when both setting the value for a cell, and for showing the value of the cell, based on isDisplay param.
 * When setting the value for a cell, we don't format precision in order to save all decimals in the db.
 */
export const formatValue = (
    value: AttributeGridRowValueTypes,
    isDisplay: boolean,
    attributeType?: AttributeTypeCodes | null,
    attributeFormat?: string | null,
    precision?: number | null,
    isMultiselect?: boolean | undefined,
) => {
    const isEmptyValue = value === undefined || value === null || value === '';

    if (!attributeType) {
        return value ?? '';
    }

    if (attributeType === AttributeTypes.boolean.value && isEmptyValue) {
        return false;
    }

    if (isDisplay) {
        if (isEmptyValue) {
            return '';
        }

        const formatter = getAttributeFormatter(
            attributeType,
            attributeFormat ?? undefined,
        );

        if (isMultiselect && Array.isArray(value)) {
            return (value as Array<any>)
                .sort((a, b) => stringSort(b, a))
                .map(formatter)
                .join(', ');
        }

        return formatter(value?.toString() ?? '', precision ?? undefined);
    } else {
        if (isMultiselect && Array.isArray(value)) {
            return isEmptyValue ? [] : value;
        }
    }

    return isEmptyValue ? '' : value;
};

export function getAttributeFormatter(
    attributeType: AttributeTypeCodes,
    attributeFormat: string | undefined,
    attributePrecision?: number | null,
): AttributeFormatterFunc {
    const format = attributeFormat
        ? AttributeFormats[attributeType][attributeFormat]
        : undefined;

    if (!format) {
        const allFormats = Object.values(AttributeFormats[attributeType]);

        if (!allFormats.length) {
            return (value) => value.toString();
        }

        return (value) =>
            allFormats[0].render(value, attributePrecision ?? undefined);
    }

    return (value) => format.render(value, attributePrecision ?? undefined);
}

export const prepareAttributeValuesPayload = (
    attribute: AttributeDefinition,
    formattedValue: AttributeGridRowValueTypes,
    entityCode: string,
): UpdateEntityAttributeValuesParams => {
    const isStandardAttribute =
        attribute.client_id === null || attribute.client_id === undefined;

    let convertedValue: AttributeGridRowValueTypes | undefined;
    let attributeValuesToUpdate: string[] = [];
    let valuesToDeleteIds: string[] = [];

    const attributeIsMultiselect =
        attribute.is_multiselect && Array.isArray(formattedValue);
    const attributeIsBooleanType =
        attribute.type === AttributeTypes.boolean.value;

    if (attributeIsMultiselect) {
        convertedValue = formattedValue;
        valuesToDeleteIds = (attribute.attributeValues ?? [])
            .filter(
                (attrValue) =>
                    !formattedValue.includes(attrValue.value) &&
                    attrValue.entity_code === entityCode,
            )
            .map((attrValue) => attrValue.id);
        attributeValuesToUpdate = convertedValue;
    }

    if (attributeIsBooleanType) {
        // Convert to 'true' or 'false' for payload but keep using booleans for the DataGrid
        convertedValue = formattedValue ? 'true' : 'false';
        attributeValuesToUpdate = [convertedValue];
    }

    if (!attributeIsMultiselect && !attributeIsBooleanType) {
        convertedValue = `${formattedValue}`;
        attributeValuesToUpdate = [convertedValue];
    }

    // non-multiselect AttributeValues with empty value must be deleted as well
    if (
        !attributeIsMultiselect &&
        convertedValue !== undefined &&
        `${convertedValue}`.length === 0
    ) {
        const attrValue = attribute.attributeValues?.find(
            (attrValue) => attrValue.entity_code === entityCode,
        );
        if (attrValue) {
            valuesToDeleteIds.push(attrValue.id);
        }
        attributeValuesToUpdate = [];
    }

    return {
        attribute_codes: [attribute.attribute_code],
        attribute_values: attributeValuesToUpdate,
        deleted_attribute_value_ids: valuesToDeleteIds,
        entity_code: entityCode,
        is_standard_attribute: isStandardAttribute,
    } as UpdateEntityAttributeValuesParams;
};

// attribute code sanitization must use the exact same rules and format as when creating attribute in the backend
export const sanitizeNameIntoAttributeCode = (name: string): string => {
    const spacesAndUnderscoresRegex = /\s+/g;
    const nonAlphanumericCharactersAndUnderscoresRegex = /[^a-z0-9_]/g;
    const multipleUnderscoresRegex = /_+/g;
    const trailingUnderscoreRegex = /_$/;

    return name
        .trim()
        .toLowerCase()
        .replace(spacesAndUnderscoresRegex, '_')
        .replace(nonAlphanumericCharactersAndUnderscoresRegex, '')
        .replace(multipleUnderscoresRegex, '_')
        .replace(trailingUnderscoreRegex, '');
};

export const getAttributesColumn = ({
    key,
    column,
    attributesByCode,
    selectedConfiguration,
    setCellAndPendingUpdateValue,
    isEditMode,
}: {
    key: string;
    column: AttributeFromAPI;
    attributesByCode: Map<string, AttributeDefinition>;
    selectedConfiguration: SavedConfiguration | null;
    setCellAndPendingUpdateValue: (
        newData: AttributeData,
        value: AttributeGridRowValueTypes,
        currentRowData: AttributeData,
        attribute?: AttributeDefinition,
    ) => void;
    isEditMode: boolean;
}) => {
    const attribute = attributesByCode?.get(column.dataIndex);

    const configColumns =
        selectedConfiguration?.filters_json?.grid_config?.columns ?? {};

    const isVisible = (columnKey: string) => {
        if (!selectedConfiguration) {
            return true;
        }

        return configColumns[columnKey]?.visible ?? false;
    };

    const customizeBoolean = (cellInfo: CustomizeCellInfoType) => {
        return cellInfo.value === true || cellInfo.value === 'true'
            ? 'Yes'
            : 'No';
    };

    return (
        <Column
            key={key}
            visible={isVisible(column.key)}
            fixed={isFixedColumn(column)}
            dataField={column.dataIndex}
            caption={column.title}
            width={200}
            validationRules={getColumnValidationRules(
                attribute?.format ?? attribute?.type,
            )}
            setCellValue={(
                newData: AttributeData,
                value: AttributeGridRowValueTypes,
                currentRowData: AttributeData,
            ) =>
                setCellAndPendingUpdateValue(
                    newData,
                    value,
                    currentRowData,
                    attribute,
                )
            }
            calculateCellValue={
                attribute?.is_multiselect
                    ? (rowData) => {
                          // When in edit mode, we need the cell value to remain an array for the Select to work correctly.
                          // When we are not in edit mode, we need the cell to be a string so that it can be filtered and sorted.
                          if (
                              !isEditMode &&
                              rowData[key] &&
                              Array.isArray(rowData[key])
                          ) {
                              return rowData[key].sort().join(', ');
                          }

                          return rowData[key];
                      }
                    : undefined
            }
            customizeText={
                attribute?.type === AttributeTypes.boolean.value
                    ? customizeBoolean
                    : undefined
            }
            calculateDisplayValue={
                attribute?.type !== AttributeTypes.number.value
                    ? (rowData: AttributeData) =>
                          formatValue(
                              rowData[column.dataIndex],
                              true,
                              attribute?.type,
                              attribute?.format,
                              attribute?.precision,
                              attribute?.is_multiselect,
                          )
                    : undefined
            }
            dataType={mapAttributeType(attribute?.type, isEditMode)}
            format={mapAttributeFormat(
                attribute?.type,
                attribute?.format,
                attribute?.precision,
            )}
            editCellRender={
                attribute?.is_multiselect || attribute?.allowed_values
                    ? (template: AttributesEditableCellTemplateProps) => (
                          <AttributesEditableSelect
                              template={template}
                              attribute={attribute}
                          />
                      )
                    : undefined
            }
            visibleIndex={
                columnOrdersByKey[column.key] ??
                configColumns[column.key]?.visibleIndex
            }
            allowEditing={isEditableColumn(column)}
            allowGrouping={!isFixedColumn(column) && !!attribute?.is_groupable}
            allowFiltering={attribute?.is_filterable}
            allowReordering={!isFixedColumn(column)}
            allowHiding={!isFixedColumn(column)}
            sortOrder={column.key === 'name' ? 'asc' : undefined}
        />
    );
};
