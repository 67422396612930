export enum REPORT_APPROVAL_TYPES {
    OneApproval = 'one_approval',
    HierarchicalApproval = 'hierarchical_approval',
    AllReviewers = 'all_reviewers',
}

export const SettingsTabKeys = {
    Approvals: 'approvals',
    General: 'general',
};

export const approvalTitles = ['Primary', 'Secondary', 'Tertiary', 'Final'];

export const APPROVALS_SETTINGS_TYPE = 'approvals';
export const DEFAULT_HIERARCHICAL_APPROVAL_LEVEL = 2;
export const MAX_HIERARCHICAL_APPROVAL_LEVEL = 4;
