import { RecurringCharge } from 'waypoint-types';

export const ChargesFields = [
    'total_monthly',
    'total_monthly_per_sq_ft',
    'total_annual',
    'total_annual_per_sq_ft',
    'rent_monthly',
    'rent_monthly_per_sq_ft',
    'rent_annual',
    'rent_annual_per_sq_ft',
    'cam_monthly',
    'cam_monthly_per_sq_ft',
    'cam_annual',
    'cam_annual_per_sq_ft',
    'taxes_insurance_monthly',
    'taxes_insurance_monthly_per_sq_ft',
    'taxes_insurance_annual',
    'taxes_insurance_annual_per_sq_ft',
    'other_monthly',
    'other_monthly_per_sq_ft',
    'other_annual',
    'other_annual_per_sq_ft',
];

export type TotalCharges = {
    total_monthly: number;
    total_monthly_per_sq_ft: number;
    total_annual: number;
    total_annual_per_sq_ft: number;
};

const isSingleFilter = (filter: (string | string[])[]) => {
    return filter.length === 3 && typeof filter[0] === 'string';
};

export const getSortedFilteredCharge = (
    charges: { [key: string]: TotalCharges },
    filter: string[],
    sort?: { selector: string; desc: string },
    skip?: number,
    take?: number,
) => {
    return Object.entries(charges)
        .filter(convertFilterToFunction(filter))

        .sort(([, valueA], [, valueB]) => {
            if (!sort) return 0;
            const diff =
                valueA[`${sort.selector}`] - valueB[`${sort.selector}`];
            return !sort.desc ? diff : -diff;
        })
        .slice(skip, take)
        .map(([key]) => key);
};

export const getChargesTotal = (
    charges: RecurringCharge[],
    chargeCategories: string[],
): { [key: string]: TotalCharges } => {
    return charges.reduce(
        (acc, charge: RecurringCharge) => {
            const key = `${charge.lease_code}__${charge.leasable_space_code}`;
            const bucketKey = charge.bucket_code;

            if (!acc[key]) {
                acc[key] = {
                    total_monthly: 0,
                    total_monthly_per_sq_ft: 0,
                    total_annual: 0,
                    total_annual_per_sq_ft: 0,
                };

                for (const bucket of chargeCategories) {
                    acc[key][`${bucket}_annual`] = 0;
                    acc[key][`${bucket}_annual_per_sq_ft`] = 0;
                    acc[key][`${bucket}_monthly`] = 0;
                    acc[key][`${bucket}_monthly_per_sq_ft`] = 0;
                }
            }

            acc[key][`${bucketKey}_annual`] += charge.monthly * 12;
            acc[key][`${bucketKey}_annual_per_sq_ft`] +=
                (charge.monthly / charge.rentable_sq_ft) * 12;
            acc[key][`${bucketKey}_monthly`] += charge.monthly;
            acc[key][`${bucketKey}_monthly_per_sq_ft`] +=
                charge.monthly / charge.rentable_sq_ft;

            acc[key].total_monthly += charge.monthly;
            acc[key].total_annual += charge.monthly * 12;
            acc[key].total_monthly_per_sq_ft +=
                charge.monthly / charge.rentable_sq_ft;
            acc[key].total_annual_per_sq_ft +=
                (charge.monthly / charge.rentable_sq_ft) * 12;

            return acc;
        },
        {} as { [key: string]: TotalCharges },
    );
};

function convertFilterToFunction<T>(
    devExtremeFilter: any[] | null,
): ([key, chargeValues]: [key: string, chargeValues: TotalCharges]) => boolean {
    if (!Array.isArray(devExtremeFilter) || !devExtremeFilter) {
        return () => true;
    }

    if (isSingleFilter(devExtremeFilter)) {
        const [field, operator, value] = devExtremeFilter;

        return ([key, chargeValues]) =>
            getFilterOperation(chargeValues, field, operator, value);
    } else {
        const logicalOperator = devExtremeFilter.includes('and') ? 'AND' : 'OR';

        const conditions = devExtremeFilter
            .filter((condition) => Array.isArray(condition))
            .map((condition) => convertFilterToFunction(condition));

        return ([key, chargeValues]) => {
            if (logicalOperator === 'AND') {
                return conditions.every((condition) =>
                    condition([key, chargeValues]),
                );
            } else {
                return conditions.some((condition) =>
                    condition([key, chargeValues]),
                );
            }
        };
    }
}

function getFilterOperation(
    item: TotalCharges,
    field: string,
    operator: string,
    value: number,
): boolean {
    if (!ChargesFields.includes(field)) {
        return true;
    }

    const itemValue = item[field];

    switch (operator) {
        case '=':
            return itemValue === value;
        case '<>':
            return itemValue !== value;
        case '>':
            return itemValue > value;
        case '>=':
            return itemValue >= value;
        case '<':
            return itemValue < value;
        case '<=':
            return itemValue <= value;
        default:
            throw new Error(`Unsupported operator: ${operator}`);
    }
}
