import { PROPERTY_CACHE_KEY } from 'config/constants';
import store from '../state/store';
import { RootStateOrAny } from 'react-redux';
import { PropertyType } from 'waypoint-types';

export const useGetEntityData = (id: string): PropertyType => {
    const properties = (store.getState() as RootStateOrAny).properties;

    return properties[`${PROPERTY_CACHE_KEY}${id}`];
};
