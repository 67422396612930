import React, { ReactElement } from 'react';

import { LastLoadedDateType } from 'components/app/as-of-date';
import { toCalendarDate } from 'components/dates/utils';
import { DASH_DASH } from 'config/constants';

interface LastLoadedDateLableProps {
    date: LastLoadedDateType | null;
}

const WIDTH = '430px';

export function LastLoadedDateLabel({
    date,
}: LastLoadedDateLableProps): ReactElement {
    return (
        <div>
            <span data-testid-message style={{ width: WIDTH }}>
                {date?.last_loaded_at
                    ? `Updated on ${toCalendarDate(date.last_loaded_at)}`
                    : 'N/A'}
            </span>
        </div>
    );
}
