import { API_URL } from 'config/constants';
import { RentRollProps, SelectedDataLevel } from 'waypoint-types';

/**
 * @param {array} entityCodes
 * @param selectedDataLevel
 * @returns rent roll for property or property group
 */

type RentRollParams = {
    entityCodes: string[];
    selectedDataLevel: SelectedDataLevel;
    selectedChargeCode?: string;
    sortedFilteredCharge?: string[];
};
type RentRollResult = {
    data: RentRollProps[];
    totalCount: number;
    summary: number[];
    groupCount: number;
};

const getRentRoll = async (
    params: RentRollParams & { queryString?: string },
): Promise<RentRollResult> => {
    const response = await fetch(
        `${API_URL}/leases/rent-roll?${params.queryString}`,
        {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                entity_codes: params.entityCodes,
                selected_data_level: params.selectedDataLevel,
                selected_charge_code: params.selectedChargeCode,
                sorted_filtered_charge: params.sortedFilteredCharge,
            }),
        },
    );

    if (!response.ok) {
        throw new Error('FAILED');
    }
    const { data, metadata } = await response.json();

    return {
        data: data,
        totalCount: metadata.totalCount,
        summary: metadata.totalSummary,
        groupCount: 0,
    };
};

export default getRentRoll;
