import React, {
    createContext,
    ReactNode,
    useContext,
    useState,
    useEffect,
} from 'react';
import {
    useGetClientAndEntityAttributeValues,
    useGetClientModes,
    useGetIncomeStatementData,
    useGetQueryParam,
    useGetThresholdRules,
} from 'waypoint-hooks';
import {
    ComparisonFilterPayload,
    buildStringToComparisonTypeString,
    getDefaultFieldValuesFor,
    getIncomeStatementReportMetadataFiltersPayload,
} from 'components/financials/comparative-income-statement/banner/ComparisonSelectionsBannerUtils';
import { ComparisonSelections } from 'components/financials/comparative-income-statement/ComparisonIncomeStatementTypes';
import {
    AccountGraphCalculations,
    AccountGraphNode,
    AttributeValue,
    ClientMode,
    FixAnyType,
    MentionableDataSource,
    Note,
    ReportMetadata,
    SelectedDataLevel,
    ThresholdRule,
} from 'waypoint-types';
import {
    COMPARISON_PERIOD,
    ACTUAL_TO_BUDGET_VALUE,
    PERIODICITY_MONTH,
    PERIODICITY_QUARTER,
    THRESHOLD_PERIODICITIES,
    NONE_VALUE,
    ACTUAL_VALUE,
    BUDGET_VALUE,
    SELECTED_PERIOD_VALUE,
} from 'components/financials/comparative-income-statement/constants';
import { KeyedMutator } from 'swr';
import moment from 'moment';
import { toISO } from 'components/dates/utils';
import {
    FinancialsProvider,
    useFinancials,
} from 'contexts/financials/FinancialsProvider';
import {
    decorateFinancialsAccountGraphForAntDesign,
    getMentionableAccounts,
    checkForThresholdExceeded,
    getDateRangeForPeriod,
} from 'waypoint-utils';
import parseDate from 'date-fns/parse';
import { message } from 'antd';
import { format } from 'date-fns';
import { PropertyDetailAttributeEnum } from 'components/propertyProfile/PropertyProfileConstants';

export const INCOME_STATEMENT_REPORT_TYPE = 'comparative-income';

interface ProviderProps {
    entityCodes?: string[];
    children: ReactNode;
    selectedDataLevel: SelectedDataLevel;
    cacheId?: string;
}
export interface VarianceReportQueryParamsType {
    entityCode: string;
    periodicity: string;
    mode: string;
    endDate: string;
    financialYearEnding: string;
}
interface IncomeStatementContextData {
    incomeStatementNodes?: AccountGraphNode[];
    entityCodes: string[];
    selectedDataLevel: SelectedDataLevel;
    asOfDate: Date | null;
    hideNull: boolean;
    setHideNull: (value: boolean) => void;
    showAllNotes: boolean;
    setShowAllNotes: (value: boolean) => void;
    hasComments: boolean;
    setHasComments: (value: boolean) => void;
    filterOverThreshold: boolean;
    setFilterOverThreshold: (value: boolean) => void;
    hasRequiredWorkflowThreshold: boolean;
    thresholdCalculations: AccountGraphCalculations | null;
    hideNotes: boolean;
    setHideNotes: (value: boolean) => void;
    showPerSF: boolean;
    setShowPerSF: (value: boolean) => void;
    showPerUnit: boolean;
    setShowPerUnit: (value: boolean) => void;
    setShowCurrentPeriod: (value: boolean) => void;
    showCurrentPeriod: boolean;
    showThresholdButton: boolean;
    reportMetadata?: ReportMetadata;
    reportMetadataFilters: ComparisonFilterPayload[] | null;
    comparisonSelections: ComparisonSelections | null;
    setSelections: (
        value: ComparisonSelections | null,
        shouldClearCommentId?: boolean,
    ) => void;
    clientModes: ClientMode[];
    isLoadingReportNotes: boolean;
    reportNotes: Note[];
    mutateReportNotes: KeyedMutator<Note[]>;
    commentIdQueryParam: string | null;
    reportMetadataIdQueryParam: string | null;
    mentionableAccounts: MentionableDataSource[];
    isError: boolean;
    isLoading: boolean;
    varianceReportQueryParams: VarianceReportQueryParamsType;
    settingsActiveCount: number;
    setSettingsActiveCount: (value: number) => void;
    onClearFilters: () => void;
    clientFinancialYearEnding?: AttributeValue;
}
const IncomeStatementContext = createContext<IncomeStatementContextData>(
    {} as IncomeStatementContextData,
);

export const IncomeStatementProvider = ({
    entityCodes,
    children,
    selectedDataLevel,
    cacheId,
}: ProviderProps) => {
    return (
        <FinancialsProvider
            entityCodes={entityCodes}
            reportType={INCOME_STATEMENT_REPORT_TYPE}
        >
            <IncomeStatementProviderInternal
                cacheId={cacheId}
                selectedDataLevel={selectedDataLevel}
            >
                {children}
            </IncomeStatementProviderInternal>
        </FinancialsProvider>
    );
};

const IncomeStatementProviderInternal = ({
    children,
    cacheId,
    selectedDataLevel,
}: {
    children?: React.ReactNode;
    cacheId?: string;
    selectedDataLevel: SelectedDataLevel;
}): JSX.Element => {
    const {
        asOfDate,
        entityCodes,
        setReportMetadataFilters,
        reportMetadataFilters,
        commentIdQueryParam,
        reportMetadataIdQueryParam,
        reportMetadata,
        isLoadingReportNotes,
        reportNotes,
        mutateReportNotes,
        isLoading: isLoadingFinancials,
        isError: isErrorFinancials,
        clearQueryParams,
    } = useFinancials();

    const [hideNull, setHideNull] = useState<boolean>(false);
    const [showAllNotes, setShowAllNotes] = useState<boolean>(false);
    const [hasComments, setHasComments] = useState<boolean>(false);
    const [filterOverThreshold, setFilterOverThreshold] =
        useState<boolean>(false);
    const [thresholdCalculations, setThresholdCalculationsState] =
        useState<AccountGraphCalculations | null>(null);
    const [hasRequiredWorkflowThreshold, setHasRequiredWorkflowThreshold] =
        useState<boolean>(false);
    const [hideNotes, setHideNotes] = useState<boolean>(false);
    const [showPerSF, setShowPerSF] = useState<boolean>(false);
    const [showPerUnit, setShowPerUnit] = useState<boolean>(false);
    const [showCurrentPeriod, setShowCurrentPeriod] = useState<boolean>(false);
    const [showThresholdButton, setShowThresholdButton] =
        useState<boolean>(false);
    const [comparisonSelections, setComparisonSelections] =
        useState<ComparisonSelections | null>(null);
    const [incomeStatementNodes, setIncomeStatementNodes] = useState<
        AccountGraphNode[] | undefined
    >(undefined);
    const [mentionableAccounts, setMentionableAccounts] = useState<
        MentionableDataSource[]
    >([]);
    const [settingsActiveCount, setSettingsActiveCount] = useState<number>(0);
    const [varianceReportQueryParams] = useState<any>({
        entityCode: useGetQueryParam('entity_code'),
        mode: useGetQueryParam('mode'),
        periodicity: useGetQueryParam('periodicity'),
        endDate: useGetQueryParam('end_date'),
        financialYearEnding: useGetQueryParam('financial_year_ending'),
    });

    const hasQueryParams = (): boolean => {
        return !!(
            varianceReportQueryParams.mode &&
            varianceReportQueryParams.periodicity &&
            varianceReportQueryParams.endDate &&
            varianceReportQueryParams.entityCode &&
            varianceReportQueryParams.financialYearEnding
        );
    };

    const { data: clientPropertyAttributes } =
        useGetClientAndEntityAttributeValues(
            [PropertyDetailAttributeEnum.FinancialYearEnding],
            true,
        );

    const clientFinancialYearEnding = clientPropertyAttributes?.find(
        (a) =>
            entityCodes.length === 1 &&
            a.attributeDefinition?.attribute_code ===
                PropertyDetailAttributeEnum.FinancialYearEnding &&
            a.entity_code === entityCodes[0],
    );

    enum ComparisonPeriods {
        SelectedPeriod = 'selected_period',
        PriorPeriod = 'prior_period',
        PriorYear = 'prior_year',
        YearTotal = 'year_total',
        NextYear = 'next_year',
        NextPeriod = 'next_period',
    }

    const getComparisonPeriodFromReportMetadata = () => {
        // NOTE: this whole function will probably be removed in the near future; this is just a safeguard for now
        // in case someone forgets to run the migration locally or a row doesn't get updated properly
        if (!reportMetadata) {
            return ComparisonPeriods.SelectedPeriod;
        }

        const filters = JSON.parse(reportMetadata.filter_raw_json);

        const isMonthOrQuarterPeriodicity =
            filters[0].periodicity === PERIODICITY_MONTH ||
            filters[0].periodicity === PERIODICITY_QUARTER;

        if (
            filters[0].start_date === filters[1].start_date &&
            filters[0].end_date === filters[1].end_date
        ) {
            return ComparisonPeriods.SelectedPeriod;
        }

        if (
            filters[0].start_date > filters[1].start_date &&
            filters[0].end_date > filters[1].end_date
        ) {
            if (isMonthOrQuarterPeriodicity) {
                return ComparisonPeriods.PriorPeriod;
            }
            return ComparisonPeriods.PriorYear;
        }

        if (
            filters[0].start_date < filters[1].start_date &&
            filters[0].end_date < filters[1].end_date
        ) {
            if (isMonthOrQuarterPeriodicity) {
                return ComparisonPeriods.NextPeriod;
            }
            return ComparisonPeriods.NextYear;
        }

        return ComparisonPeriods.YearTotal;
    };

    const getComparisonSelectionsFromReportMetadata = () => {
        if (!reportMetadata) {
            return null;
        }

        const filters = JSON.parse(reportMetadata.filter_raw_json);
        const customSelections = getDefaultFieldValuesFor(
            asOfDate as Date,
            filters[0]?.financial_year_ending ?? '12/31',
        );

        if (filters.length === 3) {
            customSelections.comparison_type = [
                filters[0].mode,
                filters[1].mode,
                filters[2].mode,
            ];
            customSelections.comparison_period_tertiary =
                filters[2].comparison_period;
            customSelections.variance_display_tertiary =
                filters[2].variance_display;
            customSelections.period_tertiary = [
                toISO(moment(filters[2].start_date)),
                toISO(moment(filters[2].end_date)),
            ];
            customSelections.variance_comparison_tertiary =
                filters[2].variance_comparison;
        } else {
            customSelections.comparison_type = [
                filters[0].mode,
                filters[1].mode,
                NONE_VALUE,
            ];
        }

        // we are no longer supporting 'month' or 'quarter' periodicity options; should be 'mtd' or 'qtd' now
        // we don't really need this check once the migration associated with these changes has been run,
        // but for the time being i'm leaving it in as an additional safeguard
        if (filters[0].periodicity === PERIODICITY_MONTH) {
            customSelections.periodicity = 'mtd';
        } else if (filters[0].periodicity === PERIODICITY_QUARTER) {
            customSelections.periodicity = 'qtd';
        } else {
            customSelections.periodicity = filters[0].periodicity;
        }

        const hasComparisonPeriod = Object.keys(filters[1]).includes(
            COMPARISON_PERIOD,
        );

        // theoretically, going forward, hasComparisonPeriod should always be true
        // this is just erring on the side of caution
        customSelections.comparison_period = hasComparisonPeriod
            ? filters[1][COMPARISON_PERIOD]
            : getComparisonPeriodFromReportMetadata();

        if (filters[1]?.variance_display) {
            customSelections.variance_display = filters[1]?.variance_display;
        }

        customSelections.period_primary = [
            toISO(moment(filters[0].start_date)),
            toISO(moment(filters[0].end_date)),
        ];
        customSelections.period_secondary = [
            toISO(moment(filters[1].start_date)),
            toISO(moment(filters[1].end_date)),
        ];

        if (filters[0].financial_year_ending) {
            customSelections.financial_year_ending =
                filters[0].financial_year_ending;
        }

        if (filters[1]?.variance_comparison) {
            customSelections.variance_comparison =
                filters[1]?.variance_comparison;
        }

        return customSelections;
    };

    const getComparisonSelectionsFromQueryParams = () => {
        if (
            !varianceReportQueryParams.periodicity ||
            !varianceReportQueryParams.mode ||
            !varianceReportQueryParams.endDate ||
            !varianceReportQueryParams.financialYearEnding
        ) {
            return null;
        }

        const customSelections = getDefaultFieldValuesFor(asOfDate as Date);
        customSelections.periodicity = varianceReportQueryParams.periodicity;
        customSelections.financial_year_ending = format(
            new Date(varianceReportQueryParams.financialYearEnding),
            'MM/dd',
        );

        const periods: FixAnyType[] = [];

        if (varianceReportQueryParams.endDate) {
            const periodEnd = parseDate(
                varianceReportQueryParams.endDate,
                'yyyy-MM-dd',
                new Date(),
            );
            periods.push(
                ...getDateRangeForPeriod(
                    varianceReportQueryParams.periodicity,
                    periodEnd,
                    'YYYY-MM-DD',
                    customSelections.financial_year_ending,
                ),
            );
        }

        // NOTE! This will need to be updated to support other comparison types if we
        // expand variance functionality for other comparison types and periods down the road
        customSelections.comparison_type = [
            ACTUAL_VALUE,
            BUDGET_VALUE,
            NONE_VALUE,
        ];
        customSelections.comparison_period = 'selected_period';

        // we also may need to expand this to support non-matching date ranges in the future
        customSelections.period_primary = [
            toISO(moment(periods[0])),
            toISO(moment(periods[1])),
        ];
        customSelections.period_secondary = [
            toISO(moment(periods[0])),
            toISO(moment(periods[1])),
        ];

        return customSelections;
    };

    const { data: clientModes, isLoading: isLoadingClientModes } =
        useGetClientModes();

    const getComparisonSelections = (): ComparisonSelections | null => {
        if (cacheId) {
            return comparisonSelections;
        }

        if (hasQueryParams()) {
            return getComparisonSelectionsFromQueryParams();
        }

        if (reportMetadata) {
            return getComparisonSelectionsFromReportMetadata();
        }

        if (asOfDate && !comparisonSelections) {
            return getDefaultFieldValuesFor(
                asOfDate,
                clientFinancialYearEnding?.value,
            );
        }

        return comparisonSelections;
    };

    const updateSelections = (
        newComparisonSelections: ComparisonSelections | null,
        shouldClearCommentId = true,
    ) => {
        shouldClearCommentId && clearQueryParams();

        setReportMetadataFilters(
            getIncomeStatementReportMetadataFiltersPayload(
                newComparisonSelections,
            ),
        );
        setComparisonSelections(newComparisonSelections);

        setHasRequiredWorkflowThreshold(false);
    };

    // income statement payload is sent here
    const {
        data: incomeStatement,
        isLoading: isLoadingIncomeStatement,
        isError: isErrorIncomeStatement,
    } = useGetIncomeStatementData(
        entityCodes,
        getIncomeStatementReportMetadataFiltersPayload(
            getComparisonSelections(),
        ),
        selectedDataLevel,
        cacheId,
    );

    React.useEffect(() => {
        if (!incomeStatement) {
            return;
        }
        const comparisonSelections = getComparisonSelections();
        const decoratedData = decorateFinancialsAccountGraphForAntDesign(
            incomeStatement,
            showCurrentPeriod,
            comparisonSelections?.periodicity ?? 'ytd',
            comparisonSelections,
        );

        setIncomeStatementNodes(decoratedData);
        setMentionableAccounts(getMentionableAccounts(decoratedData));
        setThresholdData(decoratedData);

        const thresholdCalculationsFromDecoratedData =
            decoratedData[0]?.child_summary_data?.calculations;
        setThresholdCalculationsState(thresholdCalculationsFromDecoratedData);

        if (!reportMetadataFilters) {
            setReportMetadataFilters(
                getIncomeStatementReportMetadataFiltersPayload(
                    getComparisonSelections(),
                ),
            );
        }
    }, [incomeStatement, showCurrentPeriod, comparisonSelections]);

    const setFilteredThresholdRules = (thresholdRules: ThresholdRule[]) => {
        const requiredVarianceRules =
            entityCodes.length === 1
                ? thresholdRules
                      .find((tr) => tr.entity_code === entityCodes[0])
                      ?.variance_rules.filter((vr) => vr.workflow_required)
                : [];

        setHasRequiredWorkflowThreshold(Boolean(requiredVarianceRules?.length));
    };

    const { data: thresholdRules } = useGetThresholdRules(entityCodes, () =>
        message.error('Error fetching threshold rules'),
    );

    useEffect(() => {
        if (!thresholdRules) {
            return;
        }

        setFilteredThresholdRules(thresholdRules);
    }, [thresholdRules, entityCodes]);

    useEffect(() => {
        // cacheId is only present in the case of a report widget.
        // we don't want to overwrite the settings to their defaults in that case
        if (
            cacheId ||
            commentIdQueryParam ||
            varianceReportQueryParams.entityCode
        ) {
            return;
        }
        setReportMetadataFilters(null);
        setComparisonSelections(null);
        asOfDate &&
            updateSelections({
                ...getDefaultFieldValuesFor(
                    asOfDate,
                    clientFinancialYearEnding?.value ?? '12/31',
                ),
            });
    }, [
        entityCodes.join(','),
        clientFinancialYearEnding?.value,
        cacheId,
        varianceReportQueryParams.entityCode,
    ]);

    const setThresholdData = (
        incomeStatementData: AccountGraphNode[] | null,
    ) => {
        const currentComparisonSelections = getComparisonSelections();
        if (!incomeStatementData || !currentComparisonSelections) {
            return;
        }

        const {
            comparison_type: comparison,
            periodicity,
            comparison_period,
        } = currentComparisonSelections;

        const overThresholdAllowed = () => {
            if (comparison.length === 3) {
                return (
                    buildStringToComparisonTypeString(
                        comparison.slice(0, 2),
                    ) === ACTUAL_TO_BUDGET_VALUE &&
                    comparison_period === SELECTED_PERIOD_VALUE &&
                    comparison[2] === NONE_VALUE
                );
            }
            return (
                buildStringToComparisonTypeString(comparison) ===
                    ACTUAL_TO_BUDGET_VALUE &&
                comparison_period === SELECTED_PERIOD_VALUE
            );
        };

        const shouldShowThresholdButton =
            entityCodes.length === 1 &&
            overThresholdAllowed() &&
            THRESHOLD_PERIODICITIES.has(periodicity) &&
            checkForThresholdExceeded(incomeStatementData);

        setShowThresholdButton(shouldShowThresholdButton);

        if (!shouldShowThresholdButton) {
            setFilterOverThreshold(false);
        }
    };

    const onClearFilters = () => {
        setHideNull(false);
        setShowPerSF(false);
        setShowPerUnit(false);
        setShowCurrentPeriod(false);
        setHideNotes(false);
        setShowAllNotes(false);
        setFilterOverThreshold(false);
        setHasComments(false);
        setSettingsActiveCount(0);
    };

    return (
        <IncomeStatementContext.Provider
            value={{
                entityCodes,
                selectedDataLevel,
                incomeStatementNodes,
                asOfDate: asOfDate ?? null,
                isError: isErrorFinancials || isErrorIncomeStatement,
                isLoading:
                    isLoadingIncomeStatement ||
                    isLoadingFinancials ||
                    isLoadingClientModes,
                hideNull,
                setHideNull,
                showAllNotes,
                hasComments,
                setHasComments,
                setShowAllNotes,
                filterOverThreshold,
                setFilterOverThreshold,
                hasRequiredWorkflowThreshold,
                thresholdCalculations,
                hideNotes,
                setHideNotes,
                showPerSF,
                setShowPerSF,
                showPerUnit,
                setShowPerUnit,
                showCurrentPeriod,
                setShowCurrentPeriod,
                showThresholdButton,
                reportMetadata,
                reportMetadataFilters,
                commentIdQueryParam,
                reportMetadataIdQueryParam,
                comparisonSelections: getComparisonSelections(),
                setSelections: updateSelections,
                clientModes: clientModes ?? [],
                reportNotes: reportNotes ?? [],
                isLoadingReportNotes,
                mutateReportNotes,
                mentionableAccounts,
                varianceReportQueryParams,
                settingsActiveCount,
                setSettingsActiveCount,
                onClearFilters,
                clientFinancialYearEnding,
            }}
        >
            {children}
        </IncomeStatementContext.Provider>
    );
};

export const useIncomeStatement = (): IncomeStatementContextData => {
    return useContext(IncomeStatementContext);
};
