import React, { createContext, useContext, useState } from 'react';
import {
    useClientPrefixedLocalStorage,
    useGetDataAvailabilityDate,
    useGetNotesByReportMetadataId,
    useGetQueryParam,
    useGetReportMetadata,
    useGetSelectedEntityCodes,
    useGetSelectedFilteredEntityCodes,
} from 'waypoint-hooks';
import {
    getReportMetadataByCommentId,
    getReportMetadataById,
} from 'waypoint-requests';
import useSWR, { KeyedMutator } from 'swr';
import { message } from 'antd';
import { Note, ReportMetadata } from 'waypoint-types';
import { ComparisonFilterPayload } from 'components/financials/comparative-income-statement/banner/ComparisonSelectionsBannerUtils';
import { SavedFilter } from 'contexts/saved-filters';
import { ACTIVE_GLOBAL_FILTER_KEY } from 'components/app/global-filter-drawer/GlobalFilterConstants';
import { clearQueryParam } from 'waypoint-hooks/useGetQueryParam';
import { EntityAttributesContext } from 'contexts';

interface FinancialsContextData {
    asOfDate?: Date;
    entityCodes: string[];
    reportMetadata?: ReportMetadata;
    commentIdQueryParam: string | null;
    reportMetadataIdQueryParam: string | null;
    setReportMetadataFilters: (
        filters: ComparisonFilterPayload[] | null,
    ) => void;
    reportMetadataFilters: ComparisonFilterPayload[] | null;
    isLoading: boolean;
    isError: boolean;
    isLoadingReportNotes: boolean;
    reportNotes: Note[];
    mutateReportNotes: KeyedMutator<Note[]>;
    clearQueryParams: () => void;
}

const FinancialsContext = createContext<FinancialsContextData>(
    {} as FinancialsContextData,
);

interface FinancialsProviderProps {
    reportType: string;
    entityCodes?: string[];
    children?: React.ReactNode;
}

const COMMENT_ID_QUERY_PARAM_KEY = 'comment_id';
const REPORT_METADATA_ID_QUERY_PARAM_KEY = 'report_metadata_id';

const clearQueryParams = () => {
    clearQueryParam(COMMENT_ID_QUERY_PARAM_KEY);
    clearQueryParam(REPORT_METADATA_ID_QUERY_PARAM_KEY);
};

export const FinancialsProvider = ({
    reportType,
    entityCodes: overrideEntityCodes,
    children,
}: FinancialsProviderProps) => {
    const entityAttributesContext = useContext(EntityAttributesContext);

    const entities = entityAttributesContext?.data?.entities;

    const entitiesCodeArray: string[] =
        (entities
            ?.filter((x) => typeof x.entityCode === 'string')
            .map((x) => x.entityCode) as string[]) ?? [];

    const globalFilterEntityCodes = useGetSelectedFilteredEntityCodes();
    const entityCodes = overrideEntityCodes?.length
        ? overrideEntityCodes
        : globalFilterEntityCodes;

    const [reportMetadataFilters, setReportMetadataFilters] = useState<
        ComparisonFilterPayload[] | null
    >(null);

    const {
        data: asOfDate,
        isLoading: isLoadingAsOfDate,
        isError: isErrorAsOfDate,
    } = useGetDataAvailabilityDate(entityCodes);

    const commentIdQueryParam = useGetQueryParam(COMMENT_ID_QUERY_PARAM_KEY);
    const reportMetadataIdQueryParam = useGetQueryParam(
        REPORT_METADATA_ID_QUERY_PARAM_KEY,
    );

    const { data: reportMetadataIdReportMetadata } = useSWR(
        reportMetadataIdQueryParam
            ? `/api/report-metadata/search/${reportMetadataIdQueryParam}`
            : null,
        () => getReportMetadataById(reportMetadataIdQueryParam as string),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            onError: () => {
                message.error('Could not find report.');
            },
            shouldRetryOnError: false,
        },
    );

    const { data: commentIdReportMetadata } = useSWR(
        commentIdQueryParam
            ? `/api/report-metadata/${commentIdQueryParam}`
            : null,
        () => getReportMetadataByCommentId(commentIdQueryParam as string),
        {
            revalidateOnFocus: false,
            revalidateOnMount: true,
            onError: () => {
                message.error('Could not find comment.');
            },
            shouldRetryOnError: false,
        },
    );

    React.useEffect(() => {
        const queryParamMetadata =
            reportMetadataIdReportMetadata || commentIdReportMetadata;

        if (!queryParamMetadata) {
            return;
        }

        setStateFromReportMetadata(queryParamMetadata);
    }, [commentIdReportMetadata, reportMetadataIdReportMetadata]);

    // for setting global filter based on comment_id query param metadata
    const [appliedSelectedEntityCodes, setAppliedSelectedEntityCodes] =
        useGetSelectedEntityCodes();

    const setStateFromReportMetadata = (reportMetadata: ReportMetadata) => {
        setAppliedGlobalFilter(null);
        if (!entitiesCodeArray.includes(reportMetadata.reference_id)) {
            message.error("You don't have access to this property.");
            return;
        }
        setAppliedSelectedEntityCodes([reportMetadata.reference_id]);
    };

    // for removing saved filter based on commentIdParam metadata
    const [, setAppliedGlobalFilter] =
        useClientPrefixedLocalStorage<SavedFilter | null>(
            ACTIVE_GLOBAL_FILTER_KEY,
            null,
        );

    const canRequestReportMetadata =
        !!reportMetadataFilters && entityCodes.length === 1;

    const {
        data: defaultReportMetadata,
        isError: isErrorLoadingReportMetadata,
    } = useGetReportMetadata(
        canRequestReportMetadata
            ? {
                  reportType,
                  referenceId: entityCodes[0],
                  referenceType: 'entity',
                  filters: reportMetadataFilters ?? [],
              }
            : null,
    );

    // Prefer loading by query parameter driven metadata
    const commentReportMetadata =
        commentIdReportMetadata?.report_type !== 'entity_report_widget'
            ? commentIdReportMetadata
            : defaultReportMetadata;

    const reportMetadata =
        reportMetadataIdReportMetadata ||
        commentReportMetadata ||
        defaultReportMetadata;

    const {
        data: reportNotes,
        mutate: mutateReportNotes,
        isLoading: isLoadingReportNotes,
    } = useGetNotesByReportMetadataId(reportMetadata?.id ?? '', {
        refreshInterval: 10000,
    });

    return (
        <FinancialsContext.Provider
            value={{
                isLoading: isLoadingAsOfDate,
                isError: isErrorAsOfDate || isErrorLoadingReportMetadata,
                isLoadingReportNotes,
                commentIdQueryParam,
                reportMetadataIdQueryParam,
                setReportMetadataFilters,
                reportMetadataFilters,
                asOfDate,
                entityCodes,
                reportMetadata,
                reportNotes: reportNotes ?? [],
                mutateReportNotes,
                clearQueryParams,
            }}
        >
            {children}
        </FinancialsContext.Provider>
    );
};

export const useFinancials = () => {
    return useContext(FinancialsContext);
};
