import { css } from 'emotion';
import { EntityReportWidgetListItem } from 'waypoint-types';
import { PDFHeaderParams } from 'waypoint-types/report/types';
import { WidgetTypes } from '../../constants';
import { EntityReportWidgetPdfSettings } from '../../ReportUtils';
import { sanitizeAndFormatHTML } from 'waypoint-react/content/Utils';

interface NarrativeWrapperProps {
    children: JSX.Element;
    widget: EntityReportWidgetListItem | null;
    pdfSettings?: EntityReportWidgetPdfSettings;
    pdfTemplateParams?: PDFHeaderParams;
}

const sectionTitleBottomMargins = {
    income_statement: 22,
    balance_sheet: 22,
    expiration_schedule: 10,
    rent_roll: 25,
    recurring_charge_summary: 25,
    occupancy_trend: 23,
    leasing_guidelines: 23,
    contracts: 25,
    top_tenants: 3,
    leasing_plan: 8,
    attachment: 0,
    executive_summary: -25,
    attributes: 23,
    aged_receivables: 25,
    capital_projects: 11,
    capital_plan: 11,
    property_information: 15,
    narrative: 20,
    priorities_and_objectives: 23,
    market_overview: 23,
    swot: 23,
    hold_sell: 23,
};

export const NarrativePDFWrapper = ({
    children,
    widget,
}: NarrativeWrapperProps): JSX.Element => {
    const narrativeStyle = css`
        margin: 16px 0 32px;
        width: 96%;
        word-break: break-word;
        font:
            100% 'Lato',
            'Helvetica Neun',
            sans-serif !important;
    `;

    const widgetType = widget?.widget_type;

    // Some widgets have margin top, this is required to keep the pdf margins consistent
    const sectionTitleMarginBottom = widgetType
        ? (sectionTitleBottomMargins[
              widgetType as keyof typeof sectionTitleBottomMargins
          ] ?? 20)
        : 20;

    const sectionTitleMarginTop = widgetType === 'attachment' ? 0 : 35;

    const sectionTitleStyle = css`
        margin-bottom: ${sectionTitleMarginBottom}px;
        margin-top: ${sectionTitleMarginTop}px;
        font-size: 24px;
        border-left: 1px solid rgba(0, 0, 0, 0);
        border-right: 1px solid rgba(0, 0, 0, 0);
        color: rgba(0, 0, 0, 0.87);
    `;

    const fontFamilyStyle = css`
        font-family: 'Lato', 'Helvetica Neun', sans-serif !important;
    `;

    const narrativeTextBox = () => {
        return (
            <div
                className={narrativeStyle}
                id={`narrativeTextBox_${widget?.entityReportWidgetId}`}
                dangerouslySetInnerHTML={{
                    __html: sanitizeAndFormatHTML(
                        true,
                        widget?.narrative_text ?? '',
                    ),
                }}
            />
        );
    };

    const sectionTitleElement = ![
        WidgetTypes.CoverPage,
        WidgetTypes.TableOfContents,
    ].includes(widget?.widget_type ?? '') ? (
        <div
            id={`sectionTitle_${widget?.entityReportWidgetId}`}
            className={sectionTitleStyle}
        >
            {widget?.name}{' '}
        </div>
    ) : (
        <div />
    );

    return (
        <>
            <div id="pdf-header">
                {/* We need this empty element to create the headers for devx grid exports See: waypoint-utils/pdf/PDFBuilder.ts:addHeadersToPdf */}
            </div>
            <div
                id={`narrativePDFWrapper_${widget?.widget_type}_${widget?.entityReportWidgetId}`}
                style={{ marginBottom: 30 }}
                className={fontFamilyStyle}
            >
                {sectionTitleElement}
                {widget?.narrative_position === 'above'
                    ? narrativeTextBox()
                    : null}
                {children}
                {widget?.narrative_position === 'below'
                    ? narrativeTextBox()
                    : null}
            </div>
        </>
    );
};
